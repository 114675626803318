<script>
import { defineComponent, ref } from '@vue/composition-api';
import SettingsPersonalDetailsForm from '@/components/settings-personal-details-form.vue';
import SettingsBankDetailsForm from '@/components/settings-bank-details-form.vue';
import SettingsPasswordUpdateForm from '@/components/settings-password-update-form.vue';
import BaseButton from '@/components/base/base-button.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import BaseCard from '@/components/base/base-card.vue';
import ErrorNotification from '@/components/error-notification.vue';
import notification from '@/components/notification.vue';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import { useApiErrors } from '@/use/errors';
import authApi from '@/api/auth';
import { ROUTE_CUSTOMER_DASHBOARD } from '@/lib/router';

export default defineComponent({
  components: {
    BaseIcon,
    BaseCard,
    BaseButton,
    SettingsPersonalDetailsForm,
    SettingsBankDetailsForm,
    SettingsPasswordUpdateForm,
    ErrorNotification,
    notification,
  },
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  },
  name: 'CustomerSettings',
  setup() {
    const brand = useBrand();
    const router = useRouter();
    const isLoading = ref(false);
    const passwordUpdated = ref(false);
    const { apiError, isError, clearError, setError } = useApiErrors();

    function routeToDashboard() {
      router.push({ name: ROUTE_CUSTOMER_DASHBOARD });
    }
    async function submitUpdatePassword(passwordData) {
      try {
        clearError();
        isLoading.value = true;
        await authApi.updatePassword(passwordData);
        passwordUpdated.value = true;
      } catch (error) {
        setError(error);
        isError.value = true;
      } finally {
        isLoading.value = false;
      }
    }
    return {
      brand,
      apiError,
      isError,
      isLoading,
      routeToDashboard,
      submitUpdatePassword,
      passwordUpdated,
    };
  },
});
</script>

<template>
  <div class="px-0 md:px-4 lg:mr-4">
    <div class="flex items-center mb-4 text-white">
      <span @click="routeToDashboard" class="cursor-pointer mr-4">
        <BaseIcon icon="fa-long-arrow-alt-left" class="" />
      </span>
      <h1 class="heading-2">Account Settings</h1>
    </div>
    <div class="rounded-lg p-4 md:p-6 bg-white md:shadow-md">
      <h1 class="mb-3 heading-2">Personal Details</h1>
      <p class="text-shades-darker">
        Your personal account details. If something doesn't look right and you need to update, please email us at
        <a class="text-primary" :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a> or call us at
        <a class="text-primary"> {{ brand.contactPhone }}</a
        >.
      </p>
      <SettingsPersonalDetailsForm :user="userDetails" />
      <h1 class="mb-3 heading-2 md:mt-6">Bank account</h1>
      <p class="text-shades-darker">
        Your linked bank account details. If you want to change these details, please email
        <a class="text-primary" :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a> with your updated
        bank details.
      </p>
      <SettingsBankDetailsForm class="mb-10" :bank="userDetails.bankDetails" />
      <h1 class="mb-3 heading-2 md:mt-6">Reset Password</h1>
      <notification variant="success" class="text-sm mb-4" v-show="passwordUpdated && !isError">
        <BaseIcon icon="fas fa-check-circle" size="fa-xs" />
        Password has been successfully updated.</notification
      >
      <ErrorNotification class="my-3" v-show="isError" type="is-warning" :errorMessages="apiError" />
      <SettingsPasswordUpdateForm
        @submit="submitUpdatePassword"
        :error="apiError"
        :isError="isError"
        :isLoading="isLoading"
      />
    </div>
  </div>
</template>

<style scoped>
</style>
