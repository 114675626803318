<script>
import { defineComponent } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';

export default defineComponent({
  name: 'SettingsBankDetailsForm',
  components: { BaseInput },
  props: {
    bank: {
      type: Object,
      required: true,
    },
  },
});
</script>

<template>
  <div class="mt-2">
    <BaseInput class="w-full" id="accountNameInput" :value="bank.accountName" label="Account Name" :readonly="true" />
    <BaseInput
      id="accountNumberInput"
      class="w-full"
      :value="bank.accountNumber"
      label="Account Number"
      :readonly="true"
    />
    <BaseInput class="w-full" id="bsb" :value="bank.accountBranch" label="BSB" :readonly="true" />
  </div>
</template>

<style scoped>
</style>
