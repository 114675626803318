<script>
import { defineComponent } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';

export default defineComponent({
  name: 'SettingsPersonalDetailsForm',
  components: { BaseInput },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
});
</script>

<template>
  <div>
    <div class="flex flex-wrap justify-between mt-2">
      <BaseInput class="w-2/4 pr-1" id="firstNameInput" v-model="user.firstName" label="First Name" :readonly="true" />
      <BaseInput id="lastNameInput" class="w-2/4 pl-1" v-model="user.lastName" label="Last Name" :readonly="true" />
    </div>
    <BaseInput class="w-full" id="emailInput" v-model="user.email" label="Email" :readonly="true" />
  </div>
</template>

<style scoped>
</style>
