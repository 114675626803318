<script>
import { defineComponent, reactive, computed } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';

export default defineComponent({
  name: 'SettingsPasswordUpdateForm',
  components: { BaseInput, BaseButton },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
    error: {
      required: false,
      type: Object,
      default: () => ({
        type: '',
        message: '',
        messages: [],
      }),
    },
  },
  setup(_, { emit }) {
    const form = reactive({
      password1: '',
      password2: '',
    });

    const passwordsFilled = computed(() => form.password1 !== '' && form.password2 !== '');
    const notSamePasswords = computed(() => {
      if (passwordsFilled.value) {
        return form.password1 !== form.password2;
      }
      return false;
    });
    const buttonDisabled = computed(() => !form.password1 || !form.password2 || notSamePasswords.value);

    function emitFormSubmit() {
      emit('submit', form);
    }
    return {
      buttonDisabled,
      passwordsFilled,
      notSamePasswords,
      emitFormSubmit,
      form,
    };
  },
});
</script>

<template>
  <div class="mt-2">
    <form class="flex flex-col" @submit.prevent="emitFormSubmit">
      <BaseInput
        id="password1"
        required
        minlength="8"
        type="password"
        v-model="form.password1"
        label="New Password"
        title="password must be 8 characters or more"
      />
      <BaseInput
        class="my-2"
        id="password2"
        required
        type="password"
        v-model.lazy="form.password2"
        label="Confirm New Password"
        title="password is required"
      />
      <p class="text-xs -mt-4 text-danger" v-show="notSamePasswords">passwords don't match.</p>
      <p class="text-xs text-success-dark -mt-4" v-show="!notSamePasswords && passwordsFilled">passwords match.</p>
      <div class="flex justify-end">
        <BaseButton rounded type="submit" :loading="isLoading" :disabled="buttonDisabled">Update</BaseButton>
      </div>
    </form>
  </div>
</template>

<style scoped>
</style>
